<template>
    <div>
        <loading-screen :is-loading="isLoading"></loading-screen>
        <page-header :page-title="this.t('APP.TITLE.SYSTEM')"
                     @selectAllotment="alterAllotment"
                     :allotment-index="allotmentId"
        ></page-header>
        <div class="main-container">
            <div class="row gutters mt-1">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex" style="gap: 10px">
                    <router-link to="/cliente" class="flex-grow-1">
                        <div class="info-stats4" @click="$router.push('/cliente')" style="cursor: pointer">
                            <div class="info-icon">
                                <i class="icon-users"></i>
                            </div>
                            <div class="sale-num">
                                <h3>{{ peoples.total }}</h3>
                                <p>{{ this.t('DASHBOARD.PEOPLES') }}</p>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/reserva" class="flex-grow-1">
                        <div class="info-stats4" @click="$router.push('')" style="cursor: pointer">
                            <div class="info-icon">
                                <i class="icon-lock-open"></i>
                            </div>
                            <div class="sale-num">
                                <h3>{{ reservesActive.total }}</h3>
                                <p>{{ this.t('RESERVE.INDEX_TITLE') }}</p>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/loteamento" class="flex-grow-1">
                        <div class="info-stats4">
                            <div class="info-icon">
                                <i class="icon-grid"></i>
                            </div>
                            <div class="sale-num">
                                <h3>{{ allotments.total }}</h3>
                                <p>{{ this.t('ALLOTMENT.INDEX_TITLE') }}</p>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/lote" class="flex-grow-1">
                        <div class="info-stats4">
                            <div class="info-icon">
                                <i class="icon-map-pin"></i>
                            </div>
                            <div class="sale-num">
                                <h3>{{ lots.total }}</h3>
                                <p>{{ this.t('LOTS.INDEX_TITLE') }}</p>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/dashboard-assinaturas" class="flex-grow-1"
                                 v-if="validatePermissions('ConfigClickSign:index') && hasConfigClickSign">
                        <div class="info-stats4" @click="$router.push('/dashboard-assinaturas')"
                             style="cursor: pointer">
                            <div class="info-icon">
                                <i class="icon-border_color"></i>
                            </div>
                            <div class="sale-num">
                                <h3>{{ clicksign_documents?.total }}</h3>
                                <p>{{ this.t('ASSIGN.ASSIGNS') }}</p>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/documentos" class="flex-grow-1">
                        <div class="info-stats4" @click="$router.push('')" style="cursor: pointer">
                            <div class="info-icon">
                                <i class="icon-cloud_upload"></i>
                            </div>
                            <div class="sale-num">
                                <h3>{{ this.formatSize(drive.total) }} /
                                    {{ drive.maxStore ? formatMaxStore(drive.maxStore) : '0.00' }}
                                    {{ getExtensionSize(drive.maxStore) }} </h3>
                                <p>{{ this.t('DASHBOARD.TOTAL_STORED_AVAILABLE') }}</p>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="row gutters">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" v-show="chartGraphic">
                    <div class="card">
                        <div class="p-4 row">
                            <div class="col-12 d-flex flex-row justify-content-center"
                            >
                                <h5 class="card-title">{{ this.t('GRAPHICS.PROGRESS_RESERVE_BY_ALOTMENT') }}</h5>
                            </div>
                            <div class="main-container col-12" style="min-height: 300px">
                                <div id="chartReserve" :style="blurLoadingR('reserve')"></div>
                            </div>
                            <div class=" col-12 d-flex justify-content-center w-100 pb-3"
                                 v-if="loadingReserve">
                                <div class="btn btn-primary mr-3 " :class="grapicDay ?'' :'active'"
                                     @click="setLastDaysReserveProgessGraphic(false)"><i v-if="!grapicDay"
                                                                                         class="icon-check-circle"></i>
                                    Últimos 12 Meses
                                </div>
                                <div class="btn btn-primary" @click="setLastDaysReserveProgessGraphic(true)"
                                     :class="grapicDay ?'active' :''"><i v-if="grapicDay" class="icon-check-circle"></i>
                                    Últimos 30 Dias
                                </div>
                            </div>
                        </div>
                        <div v-if="!loadingReserve" :class="{ loader: false, fadeout: !loadingReserve}"
                             class="d-flex justify-content-center align-items-center">
                            <div class="d-flex justify-content-center align-items-center">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 class="col-12" v-if="this.validatePermissions('Dashboard:resume')">Resumo de Reservas -
                    {{ getNameAllotment() }} :</h5>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex w-100"
                     v-if="this.validatePermissions('Dashboard:resume')" style="gap: 10px">
                    <div v-for="(iten, key) in this.resumReserve.reservesByStatus" :key="key" class="flex-grow-1 ">
                        <div class="info-stats4">
                            <div class="info-icon">
                                <i class="icon-lock-open"></i>
                            </div>
                            <div class="sale-num">
                                <h3>{{ iten.total }}</h3>
                                <p>{{ iten.statusName }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="flex-grow-1 ">
                        <div class="info-stats4">
                            <div class="info-icon">
                                <i class="icon-layers2"></i>
                            </div>
                            <div class="sale-num">
                                <h3>{{ this.resumReserve.reservesTotal }}</h3>
                                <p>Total</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" v-show="chartGraphicBroker">
                    <transition name="flap">
                        <div class="card" :class="{ 'flap-animation': isFlapping }">
                            <div class="pt-3 row">
                                <div class="col-12 d-flex flex-row justify-content-center pb-2"
                                >
                                    <h5 class="card-title">{{ this.t('GRAPHICS.RESERVES_CONFIRMED_BROKER_VS') }}</h5>
                                    <div class="position-absolute " style="right: 1.5vw">
                                        <Select2 :settings="{width:'200px'}" placeholder="Filtrar"
                                                 :options="[
                                                     {id: '1', text: 'QTD. Reservas Confirmadas'},
                                                     {id: '2', text: 'Preço Total - Reservas Confirmadas'}
                                             ]"
                                                 v-model="typeGrapficBroker"
                                                 @select="alterTypeGraphicBroker"
                                                 id="graphicForm">
                                        </Select2>
                                    </div>
                                </div>
                                <div class="main-container col-12" style="min-height: 350px; padding-bottom: 0">
                                    <div id="chartReserveBroker" :style="blurLoadingR('broker')"></div>
                                </div>
                            </div>
                            <div v-if="!loadingReserveBroker" :class="{ loader: false, fadeout: !loadingReserveBroker}"
                                 class="d-flex justify-content-center align-items-center">
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" v-show="chartGraphicImobi !== null">
                    <transition name="flap">
                        <div class="card" :class="{ 'flap-animation': isFlappingImobi }">
                            <div class="pt-3 row">
                                <div class="col-12 d-flex flex-row justify-content-center">
                                    <h5 class="card-title">{{ this.t('GRAPHICS.RESERVES_CONFIRMED_IMOBI_VS') }}</h5>
                                    <div class="position-absolute " style="right: 1.5vw">
                                        <Select2 :settings="{width:'200px'}" placeholder="Filtrar"
                                                 :options="[
                                                     {id: '1', text: 'QTD. Reservas Confirmadas'},
                                                     {id: '2', text: 'Preço Total - Reservas Confirmadas'}
                                             ]"
                                                 v-model="typeGrapficImobi"
                                                 @select="alterTypeGraphicImobi"
                                                 id="graphicFormImobi">
                                        </Select2>
                                    </div>
                                </div>
                                <div class="main-container col-12" style="min-height: 400px; padding-bottom: 0">
                                    <div id="chartReserveImobi" :style="blurLoadingR('imobi')"></div>
                                </div>
                            </div>
                            <div v-if="!loadingReserveImobi" :class="{ loader: false, fadeout: !loadingReserveImobi}"
                                 class="d-flex justify-content-center align-items-center">
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>

        </div>
    </div>
</template>


<script>
import Dashboard from "@/services/Dashboard";
import ApexCharts from 'apexcharts';
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import {mapState} from "vuex";
import LoadingScreen from "@/components/layouts/loadScreenComponent";
import PageHeader from "@/components/layouts/pageHeader";
import permission from "../../components/permission";
import Select2 from 'vue3-select2-component';

export default {
    name: 'Home',
    mixins: [permission],
    components: {
        LoadingScreen,
        PageHeader,
        Select2,
    },
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    computed: {
        ...mapState({
            isLoading: 'isLoading',
            hasTwoAllotments: 'hastwoAllotments',
            allotmentLoad: 'allotmentLoad',
            allotmentsStore: 'allotments',
            hasConfigClickSign: 'hasConfigClickSign'
        }),
        blurLoading() {
            if (!this.loadingMovements) {
                return '-webkit-filter: blur(5px)';
            }
            return '';
        },
    },
    beforeRouteLeave(to, from, next) {
        this.chartGraphic?.destroy();
        this.chartGraphicBroker?.destroy();
        this.chartGraphicImobi?.destroy();
        return next();
    },
    data() {
        return {
            clicksign_documents: {},
            isFlapping: false,
            isFlappingImobi: false,
            typeGrapficBroker: '1',
            typeGrapficImobi: '1',
            grapicDay: false,
            resumReserve: {reservesByStatus: []},
            chartGraphic: null,
            chartGraphicBroker: null,
            chartGraphicImobi: null,
            peoples: {},
            drive: {
                total: null,
                maxStore: null
            },
            lots: {},
            financialMovements: {},
            salesStatus: {},
            chartOptions: {},
            chartOptionsReserve: {},
            series: [],
            allotmentId: null,
            chart: null,
            chartReserve: null,
            loadingMovements: true,
            loadingReserve: true,
            loadingReserveImobi: true,
            loadingReserveBroker: true,
            allotments: {},
            reservesActive: {},
        }
    },
    mounted() {
        if (this.validatePermissions('Dashboard:resume')) {
            if (this.allotmentsStore?.length) {
                this.allotmentId = this.allotmentsStore?.find(i => i).id;
                sessionStorage.setItem('allotmentId', this.allotmentId);
                this.getResume(localStorage.getItem('companyId'));
                this.getTotalDriveStored();
                this.loadCharts();
            }
        }
    },
    watch: {
        allotmentsStore() {
            if (this.validatePermissions('Dashboard:resume')) {
                this.allotmentId = this.allotmentsStore?.find(i => i).id;
                sessionStorage.setItem('allotmentId', this.allotmentId);
                this.getResume(localStorage.getItem('companyId'));
                this.getTotalDriveStored();
                this.loadCharts();
            }
        }
    },
    methods: {
        alterTypeGraphicImobi() {
            this.isFlappingImobi = true;
            setTimeout(() => {
                this.isFlappingImobi = false;
            }, 600);
            this.renderChartReserveConfirmImobi(this.typeGrapficImobi);
        },
        alterTypeGraphicBroker() {
            this.isFlapping = true;
            setTimeout(() => {
                this.isFlapping = false;
            }, 600);
            this.renderChartReserveConfirmBroker(this.typeGrapficBroker);
        },
        getNameAllotment() {
            let name = '';
            if (this.allotmentsStore?.length) {
                name = this.allotmentsStore.find(i => i.id === this.allotmentId)?.text;
            }
            return name;
        },
        blurLoadingR(context) {
            if (context === 'imobi') {
                if (!this.loadingReserveImobi) {
                    return '-webkit-filter: blur(5px)';
                }
            }
            if (context === 'broker') {
                if (!this.loadingReserveBroker) {
                    return '-webkit-filter: blur(5px)';
                }
            }
            if (context === 'reserve') {
                if (!this.loadingReserve) {
                    return '-webkit-filter: blur(5px)';
                }
            }
            return '';
        },
        alterAllotment(id) {
            this.allotmentId = id;
            sessionStorage.setItem('allotmentId', this.allotmentId);
            this.loadCharts();
        },
        setLastDaysReserveProgessGraphic(bool) {
            this.grapicDay = bool;
            this.renderChartReserve();
        },
        loadCharts() {
            if (this.validatePermissions('Dashboard:resume') && this.allotmentId) {
                this.renderChartReserve();
                this.getResumReserveByAllotment();
                this.renderChartReserveConfirmBroker();
                this.renderChartReserveConfirmImobi();
            }
        },
        getResumReserveByAllotment() {
            Dashboard.summaryReservesByAllotment(this.allotmentId).then(resp => {
                this.resumReserve = resp.data;
            }).catch(() => {
                this.resumReserve = {reservesByStatus: []}
            })
        },
        getExtensionSize(size) {
            if (size < 1) {
                return 'MB';
            } else return 'GB';
        },
        formatMaxStore(value) {

            if (value < 1) {
                return (value * 1000).toFixed(1);
            }
            return value
        },
        async getTotalDriveStored() {
            Dashboard.totalStoredByCompany().then(resp => {
                this.drive = resp.data
            });
        },
        formatSize(fileSize) {
            if (!fileSize) return '0.0';
            if (fileSize < 1000) return `${fileSize} Bytes`;
            if (fileSize > 1000 && fileSize < 1000000) return `${(fileSize / 1000).toFixed()} KB`;
            if (fileSize >= 1000000 && fileSize < 1000000000) return `${(fileSize / 1000000).toFixed(2)} MB`;
            if (fileSize >= 1000000000) return `${(fileSize / 1000000000).toFixed(2)} GB`;
        },
        async getResume(companyId) {
            await Dashboard.companyResume(companyId).then((resp) => {
                this.financialMovements = {};
                this.financialMovements.number_total = 0;
                this.salesStatus.total = 0;
                this.peoples = resp.data.people;
                this.lots = resp.data.lots;
                this.salesStatus = resp.data.sale_status;
                this.financialMovements = resp.data.financial_moviments;
                this.allotments = resp.data.allotments;
                this.reservesActive = resp.data.reserves_active;
                this.clicksign_documents = resp.data.clicksign_documents;
            }).catch((err) => {
                this.errorMsg(err);
            })
        },
        async renderChartReserveConfirmImobi(type = '1') {
            this.loadingReserveImobi = false;
            await Dashboard.getReservesImobiByAllotment(this.allotmentId, type).then(async resp => {
                let data = resp?.data;
                if (!data || !data[0]?.length) {
                    this.chartGraphicImobi = null;
                    this.loadingReserveImobi = true;
                    return;
                }
                let chartOptionsReserve = {
                    series: data[1],
                    colors: ['#2bc3b6', '#3FBF3F'],
                    chart: {
                        type: 'bar',
                        height: '100%',
                        width: '100%',
                        toolbar: {
                            show: true,
                            offsetX: 0,
                            offsetY: 0,
                            tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset: true | '<img src="/static/icons/reset.png" width="20">',
                                customIcons: []
                            },
                            autoSelected: 'zoom'
                        },
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'center',
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded',
                            borderRadius: 3,
                        },
                    },
                    dataLabels: {
                        enabled: false,
                        position: 'bottom',
                        textAnchor: 'middle',
                        distributed: false,
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            colors: ['#000']
                        },
                        formatter: (val) => {
                            if (type === '2') {
                                return Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(val);
                            }
                            return val;
                        }
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: data[0],
                        position: 'bottom',
                        tickPlacement: 'on',
                        labels: {
                            rotate: -55,
                            rotateAlways: true,
                            style: {
                                fontSize: '10px',
                            },
                            minHeight: 150,
                            trim: true,
                        },
                    },
                    yaxis: {
                        title: {
                            text: 'reservas'
                        },
                        labels: {
                            formatter: (val) => {
                                if (type === '2') {
                                    return Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(val);
                                }
                                return val;
                            }
                        }
                    },
                    fill: {
                        opacity: 1,
                    },
                };
                this.plotChart(chartOptionsReserve, 'chartReserveImobi');
            }).catch((err) => {
                this.errorMsg(err);
                this.loadingReserveImobi = true
            });
        },
        async renderChartReserve(companyId) {
            this.loadingReserve = false;
            if (!companyId) {
                companyId = localStorage.getItem('companyId');
            }
            Dashboard.datasAnualReserve(companyId, this.allotmentId, this.grapicDay).then(resp => {
                let result = resp.data
                let seriesReserve = [];

                seriesReserve.push({
                    name: result.name1,
                    data: result.reserves
                });

                seriesReserve.push({
                    name: result.name2,
                    data: result.reservesConfirmed
                });

                seriesReserve.push({
                    name: result.name3,
                    data: result.reservesExpiredAndInterrupted
                });

                seriesReserve.push({
                    name: result.name4,
                    data: result.reservesInProgress
                });
                seriesReserve.push({
                    name: result.name5,
                    data: result.reservesSaleCanceled
                });

                let chartOptionsReserve = {
                    chart: {
                        height: '90%',
                        type: 'area',
                        width: '100%',
                        defaultLocale: 'pt',
                        locales: [{
                            name: 'pt',
                            options: {
                                toolbar: {
                                    download: 'Download SVG',
                                    selection: 'Selecionar',
                                    selectionZoom: 'Selecionar Zoom',
                                    zoomIn: 'Aumentar Zoom',
                                    zoomOut: 'Reduzir Zoom',
                                    pan: 'Panorama',
                                    reset: 'Resetar Zoom',
                                }
                            }
                        }]
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'center',
                    },
                    noData: {
                        text: 'Nenhum dado para ser carregado',
                        align: 'center',
                        verticalAlign: 'middle',
                    },
                    series: seriesReserve,
                    xaxis: {
                        convertedCatToNumeric: false,
                        categories: result.categories
                    },
                    colors: ['#0E5E4E', '#0FBC9A', '#0A4a23', '#001000', '#008822'],
                }
                this.plotChart(chartOptionsReserve, 'chartReserve');
            }).catch((err) => {
                this.errorMsg(err);
                this.loadingReserve = true
            });
        },
        async renderChartReserveConfirmBroker(type = '1') {
            this.loadingReserveBroker = false;
            await Dashboard.getReservesBrokersByAllotment(this.allotmentId, type).then(async resp => {
                let data = resp?.data;
                if (!data || !data[0]?.length) {
                    this.chartGraphicBroker = null;
                    this.loadingReserveBroker = true;
                    return;
                }
                let chartOptionsReserve = {
                    series: data[1],
                    colors: ['#2bc3b6', '#3FBF3F'],
                    chart: {
                        type: 'bar',
                        height: '100%',
                        width: '100%',
                        toolbar: {
                            show: true,
                            tools: {
                                download: false,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset: true,
                                customIcons: [
                                    {
                                        icon: '<div class="apexcharts-menu-icon" title="Menu"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path></svg></div>',
                                        index: 0,
                                        title: 'Download Personalizado',
                                        class: 'custom-download',
                                        click: function (chart) {
                                            let menuDrop = chart.el.querySelector('.apexcharts-menu');
                                            if (!chart.el.querySelector('.apexcharts-menu-open')) {
                                                setTimeout(() => {
                                                    menuDrop.innerHTML =
                                                            `
                                                                <div id="exportSVGBroker" class="apexcharts-menu-item" title="Download SVG">Download SVG</div>
                                                                <div id="exportPNGBroker" class="apexcharts-menu-item exportPNG"  title="Download PNG">Download PNG</div>
                                                                <div id="exportCSVBroker" class="apexcharts-menu-item exportCSV"  title="Download CSV">Download CSV</div>
                                                                <div id="exportXLSXBroker" class="apexcharts-menu-item exportCSV"  title="Download CSV">Download XLSX</div>
                                                    `;
                                                    menuDrop.classList.add('apexcharts-menu-open');
                                                    menuDrop.classList.add('text-left');
                                                    menuDrop.querySelector('#exportSVGBroker').addEventListener('click', () => {
                                                        try {
                                                            const paper = chart.paper();
                                                            const svgContent = paper.svg();
                                                            const svgBlob = new Blob([svgContent], { type: 'image/svg+xml;charset=utf-8' });
                                                            const svgURL = URL.createObjectURL(svgBlob);
                                                            const link = document.createElement('a');
                                                            link.href = svgURL;
                                                            link.download = 'chart.svg';
                                                            document.body.appendChild(link);
                                                            link.click();
                                                            document.body.removeChild(link);
                                                            URL.revokeObjectURL(svgURL);
                                                        } catch (error) {
                                                            console.error('Erro ao gerar o SVG:', error);
                                                        }
                                                    });
                                                    menuDrop.querySelector('#exportPNGBroker').addEventListener('click', () => {
                                                        chart.dataURI().then(({ imgURI }) => {
                                                            const link = document.createElement('a');
                                                            link.href = imgURI;
                                                            link.download = 'chart.png';
                                                            document.body.appendChild(link);
                                                            link.click();
                                                            document.body.removeChild(link);
                                                        });
                                                    });
                                                    menuDrop.querySelector('#exportCSVBroker').addEventListener('click', () => {
                                                        chart.exportToCSV()?.then(({ csv }) => {
                                                            const blob = new Blob([csv], { type: 'text/csv' });
                                                            const url = URL.createObjectURL(blob);
                                                            const a = document.createElement('a');
                                                            a.href = url;
                                                            a.download = 'chart.csv';
                                                            document.body.appendChild(a);
                                                            a.click();
                                                            document.body.removeChild(a);
                                                        });
                                                    });
                                                    menuDrop.querySelector('#exportXLSXBroker').addEventListener('click', () => {
                                                        Dashboard.exportResumBrokerByAllotment(sessionStorage.getItem('allotmentId'));
                                                    });
                                                }, 100)

                                            } else {
                                                setTimeout(() => {
                                                    menuDrop?.classList?.remove('apexcharts-menu-open')
                                                }, 1000)
                                            }
                                        }
                                    }
                                ]
                            },
                        },
                        zoom: {
                            enabled: true
                        }
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'center',
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded',
                            borderRadius: 3,
                            dataLabels: {
                                position: 'top'
                            }
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        textAnchor: 'middle',
                        distributed: false,
                        offsetX: 0,
                        offsetY: -24,
                        formatter: (val) => {
                            if (type === '2') {
                                return Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(val);
                            }
                            return val;
                        },
                        style: {
                            fontSize: '14px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 'bold',
                            colors: undefined
                        },
                        background: {
                            enabled: true,
                            foreColor: '#fff',
                            padding: 4,
                            borderRadius: 2,
                            borderWidth: 1,
                            borderColor: '#fff',
                            opacity: 0.9,
                            dropShadow: {
                                enabled: false,
                                top: 1,
                                left: 1,
                                blur: 1,
                                color: '#000',
                                opacity: 0.45
                            }
                        },
                        dropShadow: {
                            enabled: false,
                            top: 1,
                            left: 1,
                            blur: 1,
                            color: '#000',
                            opacity: 0.45
                        }
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: data[0],
                        position: 'bottom',
                        tickPlacement: 'on',
                        labels: {
                            rotate: -45,
                            trim: true,
                            rotateAlways: true,
                            minHeight: 150,
                            style: {
                                fontSize: '10px',
                            },
                        }
                    },
                    yaxis: {
                        title: {
                            text: 'reservas'
                        },
                        labels: {
                            formatter: (val) => {
                                if (type === '2') {
                                    return Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(val);
                                }
                                return val;
                            }
                        }
                    },
                    fill: {
                        opacity: 1,
                    },
                };
                this.plotChart(chartOptionsReserve, 'chartReserveBroker');
            }).catch((err) => {
                this.errorMsg(err);
                this.loadingReserveBroker = true
            });
        },

        plotChart(chartOptionsReserve, id) {
            let elChartReserve = document.getElementById(id);
            if (id === 'chartReserveBroker') {
                this.chartGraphicBroker?.destroy();
                this.chartGraphicBroker = new ApexCharts(elChartReserve, chartOptionsReserve);
                this.chartGraphicBroker.render();
                this.loadingReserveBroker = true;
            } else if (id === 'chartReserve') {
                this.chartGraphic?.destroy();
                this.chartGraphic = new ApexCharts(elChartReserve, chartOptionsReserve);
                this.chartGraphic.render();
                this.loadingReserve = true;
            } else if (id === 'chartReserveImobi') {
                this.chartGraphicImobi?.destroy();
                this.chartGraphicImobi = new ApexCharts(elChartReserve, chartOptionsReserve);
                this.chartGraphicImobi.render();
                this.loadingReserveImobi = true;
            }
        },

        errorMsg(error) {
            const erro = error?.response?.data?.validations?.errors;

            if (erro?.sql || !erro) {
                this.toast.error(error?.response?.data?.validations?.message, {
                    timeout: false,
                });
            } else {
                for (let column in erro) {
                    for (let msg in erro[column]) {
                        this.toast.error(erro[column][msg], {timeout: false});
                    }
                }
            }
        },
    },
}

</script>
<style scoped>
.flap-animation {
    animation: flap 1s forwards; /* Ajuste o tempo da animação conforme necessário */
}

@keyframes flap {
    0% {
        opacity: 0.8;
    }
    50% {
        opacity: 0.5;

    }
    100% {
        opacity: 1;
    }
}


</style>
